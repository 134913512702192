import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  ISiteTextPreset,
} from '@wix/yoshi-flow-editor/tpa-settings';

export type IStylesParams = {
  topAndBottomPadding: StyleParamType.Number;
  sidePadding: StyleParamType.Number;
  widgetBorderColor: StyleParamType.Color;
  widgetBorderWidth: StyleParamType.Number;
  widgetBorderRadius: StyleParamType.Number;
  widgetBackgroundColor: StyleParamType.Color;
  headerBackgroundColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  dividerColor: StyleParamType.Color;
  sectionsGap: StyleParamType.Number;
  pageTitleFont: StyleParamType.Font;
  pageTitleColor: StyleParamType.Color;
  sectionTitleFont: StyleParamType.Font;
  sectionTitleColor: StyleParamType.Color;
  primaryTextFont: StyleParamType.Font;
  primaryTextColor: StyleParamType.Color;
  secondaryTextFont: StyleParamType.Font;
  secondaryTextColor: StyleParamType.Color;
  actionColor: StyleParamType.Color;
  disabledColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
  borderRadius: StyleParamType.Number;
  dateTextFont: StyleParamType.Font;
  dateTextColor: StyleParamType.Color;
  timeSelectionTextColor: StyleParamType.Color;
  timeSelectionTextFont: StyleParamType.Font;
  timeSelectionBackgroundColor: StyleParamType.Color;
  bookButtonTextColor: StyleParamType.Color;
  bookButtonBGColor: StyleParamType.Color;
  bookButtonBorderColor: StyleParamType.Color;
  bookButtonHoverBorderColor: StyleParamType.Color;
  bookButtonHoverTextColor: StyleParamType.Color;
  bookButtonHoverBGColor: StyleParamType.Color;
  bookButtonTextFont: StyleParamType.Font;
  bookButtonBGColorOpacity: StyleParamType.Number;
  bookButtonHoverBGColorOpacity: StyleParamType.Number;
  bookButtonBorderColorOpacity: StyleParamType.Number;
  bookButtonHoverBorderColorOpacity: StyleParamType.Number;
  slotBackgroundColor: StyleParamType.Color;
  slotPrimaryTextFont: StyleParamType.Font;
  slotPrimaryTextColor: StyleParamType.Color;
  slotSecondaryTextFont: StyleParamType.Font;
  slotSecondaryTextColor: StyleParamType.Color;
  slotBorderWidth: StyleParamType.Number;
  slotBorderColor: StyleParamType.Color;
  mobilePageTitleFontSize: StyleParamType.Number;
  mobileSectionTitleFontSize: StyleParamType.Number;
  mobilePrimaryTextFontSize: StyleParamType.Number;
  mobileSecondaryTextFontSize: StyleParamType.Number;
  mobileDateTextFontSize: StyleParamType.Number;
  mobileTimeSelectionTextFontSize: StyleParamType.Number;
  mobileBookButtonTextFontSize: StyleParamType.Number;
  mobileSlotPrimaryTextFontSize: StyleParamType.Number;
  mobileSlotSecondaryTextFontSize: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  topAndBottomPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 48,
  },
  sidePadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 20,
  },
  widgetBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  widgetBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  widgetBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  widgetBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  sectionsGap: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 44,
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 32 }),
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 20 }),
  },
  primaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  primaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
      isMobile,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  secondaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  secondaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: 14,
      })({ textPresets }),
  },
  actionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  disabledColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  borderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  dateTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dateTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
      isMobile,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  timeSelectionTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
      isMobile,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  timeSelectionTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  timeSelectionBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  bookButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bookButtonBGColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonHoverBGColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bookButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
      isMobile,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  bookButtonBGColorOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  bookButtonHoverBGColorOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 70,
  },
  bookButtonBorderColorOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  bookButtonHoverBorderColorOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 70,
  },
  slotPrimaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }: { textPresets: ISiteTextPreset }) =>
      wixFontParam('Body-M', {
        size: 14,
      })({ textPresets }),
  },
  slotPrimaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  slotSecondaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }: { textPresets: ISiteTextPreset }) =>
      wixFontParam('Body-M', {
        size: 14,
      })({ textPresets }),
  },
  slotSecondaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  mobilePageTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },
  mobileSectionTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  mobilePrimaryTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileSecondaryTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileDateTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileTimeSelectionTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileBookButtonTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileSlotPrimaryTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileSlotSecondaryTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  slotBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  slotBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  slotBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
});
