import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Spinner, SpinnerTypes, Text } from 'wix-ui-tpa';
import { SlotsStatus } from '../../../../../types/types';
import { NoAvailableSlotsViewModel } from '../../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { SlotsPerDay } from '../../../../../utils/slotsPerDay/slotsPerDay';
import { st, classes } from './WeekAvailability.st.css';
import { DataHooks } from './constants';
import NoAvailableSlots from '../../NoAvailableSlots/NoAvailableSlots';
import { SrOnly } from '../../../../../utils/accessibility/SrOnly/SrOnly';

type DailySlotsFactory<SlotsViewModel> = (
  date: string,
  slotsViewModel: SlotsViewModel,
) => React.ReactNode;

export interface WeekAvailabilityProps<SlotsViewModel> {
  slotsStatus: SlotsStatus;
  slotsPerDays: SlotsPerDay<SlotsViewModel>[];
  noAvailableSlotsViewModel?: NoAvailableSlotsViewModel;
  dailySlotsFactory: DailySlotsFactory<SlotsViewModel>;
}

const WeekAvailability = <SlotsViewModel extends object>({
  slotsPerDays,
  slotsStatus,
  noAvailableSlotsViewModel,
  dailySlotsFactory,
}: WeekAvailabilityProps<SlotsViewModel>) => {
  const { isEditor } = useEnvironment();

  const NoAvailability = () => {
    return noAvailableSlotsViewModel &&
      slotsStatus === SlotsStatus.NO_AVAILABLE_SLOTS ? (
      <NoAvailableSlots viewModel={noAvailableSlotsViewModel!} />
    ) : null;
  };

  const Loader = () => {
    return slotsStatus === SlotsStatus.LOADING ? (
      <div className={classes.spinner}>
        <Spinner
          data-hook={DataHooks.Loader}
          diameter={24}
          isCentered={true}
          type={SpinnerTypes.slim}
        />
      </div>
    ) : null;
  };

  return (
    <div className={classes.root} data-hook={DataHooks.WeekAvailability}>
      <div className={classes.week}>
        {slotsPerDays?.map((slotsPerDay: SlotsPerDay<SlotsViewModel>) => {
          const {
            date,
            slots,
            isToday,
            isPastDate,
            dayWithoutSlots,
            accessibility,
          } = slotsPerDay;
          return (
            <div
              className={st(classes.dayWrapper, {
                isToday: !isEditor && isToday,
                isPastDate: !isEditor && isPastDate,
              })}
              data-hook={`${DataHooks.DayAvailability}-${date}`}
              role="group"
              aria-label={accessibility.dayDetailsAriaLabel}
              key={date}
            >
              <DayDetails slotsPerDay={slotsPerDay} />
              {slotsStatus === SlotsStatus.AVAILABLE_SLOTS ? (
                <Slots
                  date={date}
                  dayWithoutSlots={dayWithoutSlots}
                  accessibility={accessibility}
                >
                  {dailySlotsFactory(date, slots)}
                </Slots>
              ) : null}
            </div>
          );
        })}
      </div>
      <NoAvailability />
      <Loader />
    </div>
  );
};

interface DayDetailsProps<SlotsViewModel> {
  slotsPerDay: SlotsPerDay<SlotsViewModel>;
}

const DayDetails = <SlotsViewModel extends object>({
  slotsPerDay,
}: DayDetailsProps<SlotsViewModel>) => {
  const { weekday, day } = slotsPerDay;

  return (
    <div
      aria-hidden="true"
      className={classes.dayDetails}
      data-hook={DataHooks.DayDetails}
    >
      <Text className={classes.weekday}>{weekday}</Text>
      <Text className={classes.day}>{day}</Text>
    </div>
  );
};

interface SlotsProps {
  date: string;
  dayWithoutSlots: string;
  accessibility: {
    dayWithoutSlotsSrOnlyText: string;
    dayWithSlotsSrOnlyText: string;
  };
  children?: React.ReactNode;
}

const Slots: React.FC<SlotsProps> = ({
  date,
  dayWithoutSlots,
  accessibility: { dayWithoutSlotsSrOnlyText, dayWithSlotsSrOnlyText },
  children,
}) => {
  if (children) {
    return (
      <>
        <SrOnly data-hook={`${DataHooks.DayWithSlotsSrOnly}-${date}`}>
          {dayWithSlotsSrOnlyText}
        </SrOnly>
        {children}
      </>
    );
  }

  return (
    <>
      <SrOnly data-hook={`${DataHooks.DayWithoutSlotsSrOnly}-${date}`}>
        {dayWithoutSlotsSrOnlyText}
      </SrOnly>
      <Text
        key={`${DataHooks.DayWithoutSlots}-${date}`}
        data-hook={`${DataHooks.DayWithoutSlots}-${date}`}
        className={classes.noSlots}
        aria-hidden={true}
      >
        {dayWithoutSlots}
      </Text>
    </>
  );
};

export default WeekAvailability;
