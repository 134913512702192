export enum DataHooks {
  ROOT = 'agenda-slot',
  FORMATTED_TIME = 'agenda-slot-formatted-time',
  SERVICE_NAME = 'agenda-slot-service-name',
  AGENDA_SLOT_DETAIL = 'agenda-slot-detail',
}

export enum AgendaSlotDetailType {
  STAFF_MEMBER = 'agenda-slot-detail-staff-member',
  DURATION = 'agenda-slot-detail-duration',
  PRICE = 'agenda-slot-detail-price',
  SPOTS_LEFT = 'agenda-slot-detail-spots-left',
  LOCATION = 'agenda-slot-detail-location',
}
