import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import {
  ButtonSize,
  Button as TpaButton,
  ButtonProps as TpaButtonProps,
} from 'wix-ui-tpa';
import { classes, st } from './Button.st.css';

export interface ButtonProps extends TpaButtonProps {
  variant?: 'filled' | 'outlined';
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'filled',
  ...restProps
}) => {
  const { isMobile } = useEnvironment();

  return (
    <TpaButton
      {...restProps}
      className={st(classes.root, { isMobile, variant })}
      size={ButtonSize.medium}
      upgrade
    />
  );
};
