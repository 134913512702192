import React, { useMemo } from 'react';
import { DeprecatedDatePickerDataHooks } from './dataHooks.const';
import { classes, st } from './DeprecatedDatePicker.st.css';

import {
  DatePicker as DatePickerComponent,
  DatePickerProps as DatePickerComponentProps,
  DatePickerDateIndicationProps as dateIndicationProps,
} from 'wix-ui-tpa';

import {
  DateAvailabilityStatus,
  DatePickerViewModel,
} from '../../../ViewModel/datePickerViewModel/datePickerViewModel';
import { getLocalDateTimeStartOfDay } from '../../../../../utils/dateAndTime/dateAndTime';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { ReactComponent as DotIcon } from '../../../../../assets/Icons/DotIndication.svg';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { rfcToShiftedDate } from '@wix/bookings-date-time';
import { TriggeredByOptions } from '../../../../../types/types';

export type DatePickerProps = {
  datePickerViewModel: DatePickerViewModel;
};

const DotIndication: React.FC<{
  dateAvailabilityStatus?: DateAvailabilityStatus;
  shouldShow: boolean;
}> = (
  { dateAvailabilityStatus, shouldShow } = {
    dateAvailabilityStatus: undefined,
    shouldShow: false,
  },
) => {
  return (
    <div
      className={st(classes.dateIndicationContainer, {
        visible: shouldShow,
      })}
    >
      <DotIcon
        data-hook={DeprecatedDatePickerDataHooks.DOT_ICON}
        className={
          dateAvailabilityStatus === DateAvailabilityStatus.HAS_AVAILABLE_SLOTS
            ? classes.availableDateIndication
            : classes.notAvailableDateIndication
        }
      />
    </div>
  );
};

const DeprecatedDatePicker: React.FC<DatePickerProps> = ({
  datePickerViewModel,
}) => {
  const {
    selectedDate: localDateTime,
    dateAvailabilityStatuses,
    firstDayOfTheWeek,
    todayLocalDateTime,
    locale,
    accessibility: { nextAriaLabel, prevAriaLabel },
  } = datePickerViewModel;
  const { onDateSelected, onMonthSelected } = useCalendarActions();
  const { isMobile } = useEnvironment();

  const onDateChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onDateSelected(
      localDateTimeStartOfDay,
      TriggeredByOptions.DATE_SELECTED,
    );
  };

  const onMonthChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onMonthSelected(localDateTimeStartOfDay);
  };

  const dateIndications = ({ date, isSelected }: dateIndicationProps) => {
    const dateAvailabilityStatus = dateAvailabilityStatuses.get(
      getLocalDateTimeStartOfDay(date),
    );
    return (
      <DotIndication
        dateAvailabilityStatus={dateAvailabilityStatus}
        shouldShow={!!dateAvailabilityStatus && !isSelected}
      />
    );
  };

  const value = useMemo(() => rfcToShiftedDate(localDateTime), [localDateTime]);
  const today = useMemo(
    () => rfcToShiftedDate(todayLocalDateTime),
    [todayLocalDateTime],
  );

  return (
    <DatePickerComponent
      data-hook={DeprecatedDatePickerDataHooks.DATE_PICKER}
      className={st(classes.root, { isMobile })}
      value={value}
      today={today}
      onChange={onDateChange}
      onMonthChange={onMonthChange}
      dateIndication={dateIndications}
      firstDayOfWeek={firstDayOfTheWeek}
      rightArrowAriaLabel={nextAriaLabel}
      leftArrowAriaLabel={prevAriaLabel}
      removeOuterPadding
      locale={locale as DatePickerComponentProps['locale']}
    />
  );
};

export default DeprecatedDatePicker;
