import React, { useEffect } from 'react';
import { CalendarViewModel } from '../ViewModel/viewModel';
import { CalendarActions } from '../Actions/actions';
import {
  WidgetProps,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { BottomSectionStatus } from '../ViewModel/widgetViewModel/widgetViewModel';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Spinner } from 'wix-ui-tpa';
import { CalendarActionsProvider } from '../Hooks/useCalendarActions';
import { CalendarFlowProvider, FlowElements, useFlow } from '../Hooks/useFlow';
import { useSettingsParams } from '../Hooks/useSettingsParams';
import { PresetProvider } from '../Hooks/usePreset';
import Header from './Header/Header';
import RescheduledBookingDetails from './RescheduledBookingDetails/RescheduledBookingDetails';
import Dialog from './Dialog/Dialog';
import Toast from './Toast/Toast';
import Sidebar from './Sidebar/Sidebar';
import { BackButton } from './BackButton/BackButton';
import DailyAgendaWeeklyPickerLayout from './DailyLayout/DailyAgendaWeeklyPickerLayout/DailyAgendaWeeklyPickerLayout';
import NewWeeklyTimeSlotsLayout from './WeeklyLayout/WeeklyTimeSlotsLayout/WeeklyTimeSlotsLayout';
import DeprecatedWeeklyTimeSlotsLayout from './WeeklyLayout/DeprecatedWeeklyTimeSlotsLayout/WeeklyTimeSlotsLayout';
import WeeklyTimetableLayout from './WeeklyLayout/WeeklyTimetableLayout/WeeklyTimetableLayout';
import DailyTimeSlotsLayout from './DailyLayout/DailyTimeSlotsLayout/DailyTimeSlotsLayout';
import MonthlyDatePicker from './DailyLayout/DatePicker/MonthlyDatePicker/MonthlyDatePicker';
import WeeklyDatePicker from './DailyLayout/DatePicker/WeeklyDatePicker/WeeklyDatePicker';
import EmptyState from './EmptyState/EmptyState';
import {
  DESKTOP_WIDGET_MIN_WIDTH,
  MOBILE_WIDGET_MIN_WIDTH,
} from '../../../constants/constants';
import { classes, st } from './Widget.st.css';

export interface ControllerProps extends CalendarActions, CalendarViewModel {}

const WidgetWrapper: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const { widgetViewModel } = props;

  return (
    <>
      {widgetViewModel?.emptyStateViewModel ? (
        <>
          <BackButton viewModel={widgetViewModel.backButtonViewModel} />
          <EmptyState {...widgetViewModel?.emptyStateViewModel} />
        </>
      ) : (
        <CalendarFlowProvider value={widgetViewModel.focusedElement}>
          <PresetProvider value={widgetViewModel.preset}>
            <CalendarActionsProvider value={props}>
              <Widget {...props} />
            </CalendarActionsProvider>
          </PresetProvider>
        </CalendarFlowProvider>
      )}
    </>
  );
};

const Widget: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const {
    isMobile,
    isRTL,
    dimensions: { width, height },
    isEditor,
  } = useEnvironment();

  useEffect(() => {
    const widgetMinWidth = isMobile
      ? MOBILE_WIDGET_MIN_WIDTH
      : DESKTOP_WIDGET_MIN_WIDTH;
    if (isEditor && typeof width === 'number' && width < widgetMinWidth) {
      props.host.resizeComponent &&
        props.host.resizeComponent({
          width: widgetMinWidth,
          height,
          mobileResize: isMobile,
        });
    }
  }, [width]);

  useEffect(() => {
    if (props.widgetViewModel.status === BottomSectionStatus.LOADING) {
      props.initializeWidget();
    }
  }, [props.widgetViewModel.status]);

  const getWidgetLoader = () => (
    <div data-hook="widget-loader" className={classes.loader}>
      <Spinner diameter={50} isCentered={true} />
    </div>
  );

  const getWidgetContentByStatus = () => {
    switch (props.widgetViewModel.status) {
      case BottomSectionStatus.LOADING:
        return getWidgetLoader();
      case BottomSectionStatus.LOADED:
        return <BottomSectionContent {...props} />;
      default:
        return getWidgetLoader();
    }
  };

  return (
    <div
      className={st(classes.root, {
        isRTL,
        isMobile,
        textAlignment: settings.get(settingsParams.textAlignment),
        columnAlignment: settings.get(settingsParams.columnAlignment),
        layout: settings.get(settingsParams.calendarLayout),
        showTopSection:
          !!props.headerViewModel || !!props.rescheduleDetailsViewModel,
      })}
      dir={isRTL ? 'rtl' : 'ltr'}
      data-hook="BookingCalendar-wrapper"
    >
      <div data-hook="top-section" className={classes.topSection}>
        <BackButton viewModel={props.widgetViewModel.backButtonViewModel} />
        <div className={classes.topSectionHeader}>
          <div
            data-hook="top-section-content"
            className={classes.topSectionContentContainer}
          >
            {props.rescheduleDetailsViewModel && (
              <RescheduledBookingDetails
                viewModel={props.rescheduleDetailsViewModel}
              />
            )}
            {props.headerViewModel ? (
              <Header viewModel={props.headerViewModel!} />
            ) : null}
          </div>
        </div>
      </div>
      <div data-hook="bottom-section" className={classes.bottomSection}>
        <div
          data-hook="bottom-section-content"
          className={classes.bottomSectionContentContainer}
        >
          {getWidgetContentByStatus()}
        </div>
      </div>
      <Dialog viewModel={props.dialogViewModel!} />
      <Toast viewModel={props.toastViewModel!} />
    </div>
  );
};

const BottomSectionContent: React.FC<ControllerProps> = (props) => {
  const bottomSectionRef = useFlow(FlowElements.BOTTOM_SECTION);
  const { sidebarViewModel } = props;

  return (
    <div className={classes.bottomSectionContent} ref={bottomSectionRef}>
      <Body {...props} />
      {sidebarViewModel ? <Sidebar viewModel={sidebarViewModel} /> : null}
    </div>
  );
};

const Body: React.FC<ControllerProps> = ({
  widgetViewModel: { errorNotificationText },
  bodyViewModel,
}) => {
  const { experiments } = useExperiments();
  const {
    weeklyTimeSlotsLayoutViewModel,
    dailyTimeSlotsMonthlyPickerLayoutViewModel,
    dailyTimeSlotsWeeklyPickerLayoutViewModel,
    weeklyTimetableLayoutViewModel,
    dailyAgendaWeeklyPickerLayoutViewModel,
  } = bodyViewModel!;

  const isWeekAvailabilityRefactorEnabled = experiments.enabled(
    'specs.bookings.calendarWeekAvailabilityRefactor',
  );

  const WeeklyTimeSlotsLayout = isWeekAvailabilityRefactorEnabled
    ? NewWeeklyTimeSlotsLayout
    : DeprecatedWeeklyTimeSlotsLayout;

  return (
    <div className={classes.body} data-hook="body">
      {dailyTimeSlotsMonthlyPickerLayoutViewModel ? (
        <DailyTimeSlotsLayout
          viewModel={dailyTimeSlotsMonthlyPickerLayoutViewModel}
          errorNotificationText={errorNotificationText}
          datePickerComponent={
            <MonthlyDatePicker
              viewModel={
                dailyTimeSlotsMonthlyPickerLayoutViewModel.datePickerViewModel
              }
            />
          }
        />
      ) : null}
      {dailyTimeSlotsWeeklyPickerLayoutViewModel ? (
        <DailyTimeSlotsLayout
          viewModel={dailyTimeSlotsWeeklyPickerLayoutViewModel}
          errorNotificationText={errorNotificationText}
          datePickerComponent={
            <WeeklyDatePicker
              viewModel={
                dailyTimeSlotsWeeklyPickerLayoutViewModel.datePickerViewModel
              }
            />
          }
        />
      ) : null}
      {weeklyTimeSlotsLayoutViewModel ? (
        <WeeklyTimeSlotsLayout
          viewModel={weeklyTimeSlotsLayoutViewModel}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
      {weeklyTimetableLayoutViewModel ? (
        <WeeklyTimetableLayout
          viewModel={weeklyTimetableLayoutViewModel}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
      {dailyAgendaWeeklyPickerLayoutViewModel ? (
        <DailyAgendaWeeklyPickerLayout
          viewModel={dailyAgendaWeeklyPickerLayoutViewModel}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
    </div>
  );
};

export default WidgetWrapper;
