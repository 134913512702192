import React from 'react';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import WeekPicker from '../WeekPicker/WeekPicker';
import { WeeklyTimetableLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { classes } from './WeeklyTimetableLayout.st.css';
import { DataHooks } from './constants';
import WeekAvailability, {
  DayWithoutSlotsIndicationFactory,
  DayWithSlotsFactory,
} from '../WeekAvailability/WeekAvailability';
import { AgendaSlotsViewModel } from '../../../ViewModel/agendaSlotsViewModel/agendaSlotsViewModel';
import { AgendaSlot } from '../../AgendaSlot/AgendaSlot';

export interface WeeklyTimetableLayoutProps {
  viewModel: WeeklyTimetableLayoutViewModel;
  errorNotificationText: string;
}

const WeeklyTimetableLayout: React.FC<WeeklyTimetableLayoutProps> = ({
  viewModel: { weekPickerViewModel, slotsPerDays, slotsStatus },
  errorNotificationText,
}) => {
  const dayWithSlotsFactory: DayWithSlotsFactory<AgendaSlotsViewModel> = ({
    slotsViewModel,
  }) =>
    slotsViewModel.length > 0 ? (
      <>
        {slotsViewModel.map((slot) => (
          <AgendaSlot key={slot.id} slot={slot} />
        ))}
      </>
    ) : null;

  const dayWithoutSlotsIndicationFactory: DayWithoutSlotsIndicationFactory<
    AgendaSlotsViewModel
  > = ({ text }) => text;

  return (
    <div className={classes.root} data-hook={DataHooks.WeeklyTimetableLayout}>
      <ErrorNotification errorText={errorNotificationText} />
      <WeekPicker viewModel={weekPickerViewModel!} />
      <WeekAvailability
        slotsPerDays={slotsPerDays!}
        slotsStatus={slotsStatus}
        dayWithSlotsFactory={dayWithSlotsFactory}
        dayWithoutSlotsIndicationFactory={dayWithoutSlotsIndicationFactory}
      />
    </div>
  );
};

export default WeeklyTimetableLayout;
