import React from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import SectionHeader from '../../SectionHeader/SectionHeader';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import TimezoneSelection from '../../TimezoneSelection/TimezoneSelection';
import TimeSlotsSelection from '../../TimeSlotsSelection/TimeSlotsSelection';
import TimePicker from '../TimePicker/TimePicker';
import NoAvailableSlots from '../../NoAvailableSlots/NoAvailableSlots';
import DeprecatedDatePicker from '../DeprecatedDatePicker/DeprecatedDatePicker';
import { DailyTimeSlotsLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { DataHooks } from './constants';
import { TimeSlotsSelectionViewModel } from '../../../ViewModel/timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';
import { TimeSlotsSelectionDisplayMode } from '../../TimeSlotsSelection/constants';
import { classes } from '../DailyLayout.st.css';

export interface DailyTimeSlotsLayoutProps {
  viewModel: DailyTimeSlotsLayoutViewModel;
  errorNotificationText: string;
  datePickerComponent: React.ReactNode;
}

const DailyTimeSlotsLayout: React.FC<DailyTimeSlotsLayoutProps> = ({
  viewModel: {
    bodyTitle,
    datePickerViewModel,
    timePicker,
    timezoneSelectionViewModel,
  },
  errorNotificationText,
  datePickerComponent,
}) => {
  const {
    status,
    formattedSelectedDate,
    noAvailableSlotsViewModel,
    slots: slotsViewModel,
    accessibility,
    timeSlotsNotificationViewModel,
  } = timePicker;
  const { experiments } = useExperiments();
  const isWeeklyLayoutMobileEnabled = experiments.enabled(
    'specs.bookings.weeklyLayoutMobile',
  );

  return (
    <div data-hook={DataHooks.DailyTimeSlotsLayout}>
      <SectionHeader
        title={bodyTitle}
        suffix={
          <TimezoneSelection
            viewModel={timezoneSelectionViewModel}
            dataHook={DataHooks.Timezone}
          />
        }
      />
      <ErrorNotification errorText={errorNotificationText} />
      <div className={classes.root}>
        {isWeeklyLayoutMobileEnabled ? (
          datePickerComponent
        ) : (
          <DeprecatedDatePicker datePickerViewModel={datePickerViewModel!} />
        )}
        <TimePicker
          date={formattedSelectedDate}
          status={status}
          accessibility={accessibility}
          timeSlotsNotificationViewModel={timeSlotsNotificationViewModel}
          availableSlotsComponent={
            <TimeSlotsSelection
              viewModel={slotsViewModel as TimeSlotsSelectionViewModel}
              displayMode={TimeSlotsSelectionDisplayMode.Stretch}
              date={formattedSelectedDate || ''}
            />
          }
          noAvailableSlotsComponent={
            <NoAvailableSlots viewModel={noAvailableSlotsViewModel!} />
          }
        />
      </div>
    </div>
  );
};

export default DailyTimeSlotsLayout;
